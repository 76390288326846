import React from "react"

import Footer from "components/shared/Footer"

const AdminProject = () => {
	return (
		<>
			<Footer />
		</>
	)
}

export default AdminProject
